import { useState, useRef } from 'react';
import InputField from '../components/InputField'
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Body from '../components/Body';
import Form from 'react-bootstrap/Form'

const REACT_APP_URLS_FULL_API_URL = process.env.REACT_APP_URLS_FULL_API_URL ||
  'http://linode1.cocajola.xyz:5005/url-scanner';
//const REACT_APP_URLS_FULL_API_URL = 'http://localhost:5005/url-scanner'

export default function UrlsPage() {
    const [formErrors, setFormErrors] = useState({})
    const urlField = useRef()
    const [isProcessing, setIsProcessing] = useState(false);
    const [imageSrc, setImageSrc] = useState(null);

    const handleSubmit = async (ev) => {
        ev.preventDefault();
        setIsProcessing(true);
        const response = await fetch(REACT_APP_URLS_FULL_API_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                url: urlField.current.value,
            }),
        });

        const textData = await response.text(); // Get the response as text
        try {
            const data = JSON.parse(textData); // Try to parse it as JSON

            setIsProcessing(false);
            if (response.ok) {
                setFormErrors({});
                setImageSrc(data.imageSrc);
            } else {
                setFormErrors({ url: 'Failed to scan' });
            }
        } catch (jsonError) {
            console.error("JSON parsing error:", jsonError);
            console.error("Received response:", textData);
            setIsProcessing(false);
            setFormErrors({ url: 'Invalid JSON response' });
        }
    }

    return (
        <Body sidebar>
            <Form onSubmit={handleSubmit}>
                <InputField name='url' label='Enter URL' error={formErrors.url} fieldRef={urlField}></InputField>
                <Button type="submit" disabled={isProcessing}>
                    {isProcessing
                    ? <Spinner as="span" animation="border" size="sm" /> 
                    : 'Scan!'}
                </Button>
                <br /> <br />
                <pre>{imageSrc && <img src={imageSrc} alt="Screenshot" style={{ maxWidth: '100%', height: 'auto' }} />}</pre>
            </Form>
        </Body>
    );
}