import Container from "react-bootstrap/Container";
import Stack from "react-bootstrap/Stack";
import Sidebar from "./Sidebar";
import FlashMessage from './FlashMessage';

export default function Body({ sidebar, className, children }) {
    const bodyClassName = className || "Body";

    return (
        <Container className={bodyClassName}>
            <Stack direction='horizontal'>
                {sidebar && <Sidebar />}
                <Container className='Content' fluid>
                    <FlashMessage />
                    {children}
                </Container>
            </Stack>
        </Container>
    );
}