import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import { NavLink } from 'react-router-dom'

export default function Sidebar(){
    return (
        <Navbar sticky="top" className='flex-column Sidebar'>
            <Nav.Item>
                <Nav.Link as={NavLink} to='/'>Feed</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link as={NavLink} to='/explore'>Explore</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link as={NavLink} to ='/clicker'>Clicker</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link as={NavLink} to ='/reflect'>Reflector</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link as={NavLink} to ='/uploader'>Uploader</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link as={NavLink} to ='/pusher'>Pusher</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link as={NavLink} to ='/urls'>URLS</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link as={NavLink} to ='/sms'>SMS</Nav.Link>
            </Nav.Item>
        </Navbar>
    );
}