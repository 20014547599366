import Button from 'react-bootstrap/Button'

export default function More({ pagi, loadNext }) {
    let thereAreMore = false;
    if (pagi) {
        const { offset, count, total } = pagi;
        thereAreMore = offset + count < total;
    }
    return (
        <div className='More'>
            {thereAreMore &&
                <Button variant='outline-primary' onClick={ loadNext }>
                    More &raquo;
                </Button>
            }
        </div>
    )
}