import Form from 'react-bootstrap/Form'

export default function InputField({ name, type, placeholder, error, fieldRef }) {
    return(
        <Form.Group controlId={name} className='InputField'>
            <Form.Control
                type={type || 'text'}
                placeholder={placeholder}
                ref={fieldRef}>
            </Form.Control>
            <Form.Text className='text-danger'>{error}</Form.Text>
        </Form.Group>
    )
}