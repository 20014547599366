import Stack from 'react-bootstrap/Stack'
import Image from 'react-bootstrap/Image'
import TimeAgo from './TimeAgo'
import { Link } from 'react-router-dom'

export default function Post({ post }) {
    return (
        <Stack direction='horizontal' className='Post'>
            <Image alt={post.author.username} src={post.author.avatar_url + '&s=48'} />
            <div>
                <p>
                    <Link to={'/user/' + post.author.username}>
                        {post.author.username}
                    </Link>
                    &nbsp;&mdash;&nbsp;
                    <TimeAgo isoDate={post.timestamp} />;
                </p>
                <p>{post.text}</p>
            </div>
        </Stack>
    );
}