import Form from 'react-bootstrap/Form';
import InputField from '../components/InputField';
import { useState, useEffect, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import { useApi } from '../contexts/ApiProvider';
import { useFlash } from '../contexts/FlashProvider';
import ReactGA from 'react-ga4';

export default function RegistrationPage({ closeModal }) {
  const [formErrors, setFormErrors] = useState({});
  const usernameField = useRef();
  const passwordField = useRef();
  const password2Field = useRef();
  const emailField = useRef();
  const api = useApi();
  const flash = useFlash();

  const onSubmit = async (ev) => {
    ev.preventDefault();

    const username = usernameField.current.value;
    const password = passwordField.current.value;
    const password2 = password2Field.current.value;
    const email = emailField.current.value;

    const errors = {};
    if (!username) {
      errors.username = 'username must not be empty';
    }
    if (!password) {
      errors.password = 'password must not be empty';
    }
    if (!email) {
      errors.email = 'email must not be empty';
    }
    if (password !== password2) {
      errors.password2 = 'password is not the same';
    }

    setFormErrors(errors);

    const data = await api.post('/users', {
      username: usernameField.current.value,
      password: passwordField.current.value,
      email: emailField.current.value,
    });
    if (!data.ok) {
      setFormErrors(data.body.errors.json);
      ReactGA.event('sign_up', { success: false });
      console.log('sign_up false');
    } else {
      setFormErrors({});
      closeModal();
      flash('Registration succeeded!', 'success');
      ReactGA.event('sign_up', { success: true });
      console.log('sign_up true');
    }
  };

  useEffect(() => {
    usernameField.current.focus();
  }, []);

  return (
    <Form onSubmit={onSubmit}>
      <InputField
        name="username"
        placeholder="Username"
        error={formErrors.username}
        fieldRef={usernameField}
      />
      <InputField
        name="email"
        placeholder="Email Address"
        error={formErrors.email}
        fieldRef={emailField}
      />
      <InputField
        name="password"
        type="password"
        placeholder="Password"
        error={formErrors.password}
        fieldRef={passwordField}
      />
      <InputField
        name="password2"
        type="password"
        placeholder="Password again"
        error={formErrors.password2}
        fieldRef={password2Field}
      />
      <Button variant="primary" type="submit">
        Register
      </Button>
    </Form>
  );
}