import { useState, useRef, useEffect } from 'react'
import Body from '../components/Body'
import Form from 'react-bootstrap/Form'
import InputField from '../components/InputField'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { useNavigate, useLocation } from 'react-router-dom'
import { useUser } from '../contexts/UserProvider'
import { useFlash } from '../contexts/FlashProvider'
import ReactGA from 'react-ga4'
import RegistrationPage from './RegistrationPage'
import ResetRequestPage from './ResetRequestPage'

export default function LoginPage() {
    const [formErrors, setFormErrors] = useState({})
    const usernameField = useRef()
    const passwordField = useRef()
    const { login } = useUser()
    const flash = useFlash()
    const navigate = useNavigate()
    const location = useLocation()
    const [showRegModal, setShowRegModal] = useState(false)
    const [showResetModal, setShowResetModal] = useState(false)

    useEffect(() => {
        usernameField.current.focus()
    }, [])

    const handleRegClick = () => {
        setShowRegModal(true);
    }

    const handleResetClick = () => {
        setShowResetModal(true);
    }

    const handleCloseModal = () => {
        setShowRegModal(false);
        setShowResetModal(false);
    }

    const onSubmit = async (ev) => {
        ev.preventDefault()
        const username = usernameField.current.value
        const password = passwordField.current.value

        const errors = {}
        if (!username) {
            errors.username = 'username must not be empty'
        }
        if (!password) {
            errors.password = 'password must not be empty'
        }
        setFormErrors(errors)
        if (errors.username || errors.password) {
            return
        }

        const result = await login(username, password)
        if (result === 'fail') {
            flash('Invalid username or password', 'danger')
            ReactGA.event('login', { success: false })
            console.log('login false')
        }
        else if (result === 'ok') {
            let next = '/'
            if (location.state && location.state.next) {
                next = location.state.next
            }
            navigate(next)
            ReactGA.event('login', { success: true })
            console.log('ga login event sent')
        }
    }

    return (
        <Body className="form-login">
            <h1>Microblog</h1>
            <Form onSubmit={onSubmit} className='d-flex flex-column'>
                <InputField name='username' placeholder='Username or email address' error={formErrors.username} fieldRef={usernameField} />
                <InputField name='password' type='password' placeholder='Password' error={formErrors.password} fieldRef={passwordField} />
                <Button variant='primary' type='submit'>Login</Button>
            </Form>
            <hr />
            <p>Don't have an account?<br />
                <Button variant='link' onClick={handleRegClick}>
                    Register Here!
                </Button>
            </p>
            <p>Forgot your password?<br />
                <Button variant='link' onClick={handleResetClick}>
                    Reset Here!
                </Button>
            </p>

            <Modal show={showRegModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Registration</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <RegistrationPage closeModal={handleCloseModal} />
                </Modal.Body>
            </Modal>

            <Modal show={showResetModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Password Reset</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ResetRequestPage closeModal={handleCloseModal} />
                </Modal.Body>
            </Modal>
        </Body>
    );
}