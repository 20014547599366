import Image from 'react-bootstrap/Image'
import Form from 'react-bootstrap/Form'
import Stack from 'react-bootstrap/Stack'
import InputField from './InputField'
import { useApi } from '../contexts/ApiProvider'
import { useUser } from '../contexts/UserProvider'
import { useState, useRef, useEffect } from 'react'

export default function Write({ showPosts }) {
    const [formErrors] = useState({})
    //console.log(formErrors)
    const textField = useRef()
    const api = useApi()
    const { user } = useUser()


    useEffect(() => {
        textField.current.focus()
    }, [])

    const onSubmit = async (ev) => {
        ev.preventDefault()
        const response = await api.post('/posts', {
            text: textField.current.value,
        })
        if (response.ok) {
            showPosts(response.body)
            textField.current.value = ''
        }
    }

    return (
        <Stack direction='horizontal' gap={3} className='Write'>
            <Image src={user.avatar_url + '&s=64'} roundedCircle />
            <Form onSubmit={onSubmit}>
                <InputField name='text' placeholder='anything to share?' error={formErrors.text} fieldRef={textField} />
            </Form>
        </Stack>
    )
}