import React, { useState } from 'react';
import Body from '../components/Body';
import Button from 'react-bootstrap/Button'
import { useFlash } from '../contexts/FlashProvider'

const REACT_APP_REFLECT_FULL_API_URL = process.env.REACT_APP_REFLECT_FULL_API_URL ||
  'http://linode1.cocajola.xyz:5002/reflect-api';

export default function ReflectPage() {
  const [headers, setHeaders] = useState(null);
  const [error, setError] = useState('');
  const flash = useFlash();

  const fetchHeaders = async () => {
    try {
      const response = await fetch(REACT_APP_REFLECT_FULL_API_URL, {
        method: 'POST'
      })
      if (!response.ok) {
        flash('Failed to reflect headers', 'danger')
        console.log('reflect headers false')
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      if (data) {
        flash('Reflect succeeded!', 'success');
        setHeaders(data);
        setError('');
      }
    } catch (e) {
      flash('Failed to reflect headers', 'danger');
      console.log('reflect headers false');
      setError(`There was a problem fetching the headers: ${e.message}`);
      setHeaders(null);
    }
  };

  return (
    <Body sidebar>
        <Button onClick={fetchHeaders}>Reflect Headers!</Button>
        <br />
        <br />
        {headers && (
            <pre>{JSON.stringify(headers, null, 2)}</pre>
        )}
        {error && <div className="error">{error}</div>}
    </Body>
  );
}
