import Form from 'react-bootstrap/Form'
import Body from '../components/Body'
import InputField from '../components/InputField'
import {useState, useEffect, useRef} from 'react'
import Button from 'react-bootstrap/Button'
import { useApi } from '../contexts/ApiProvider'
import { useFlash } from '../contexts/FlashProvider'
import { useNavigate } from 'react-router-dom'

export default function ChangePasswordPage() {
    const [formErrors, setFormErrors] = useState({})
    const oldPasswordField = useRef()
    const passwordField = useRef()
    const password2Field = useRef()
    const api = useApi()
    const navigate = useNavigate()
    const flash = useFlash()

    const onSubmit = async (ev) => {
        ev.preventDefault()
        if (passwordField.current.value !== password2Field.current.value) {
            setFormErrors({password2: "Passwords don't match"})
        }
        else {
            const response = await api.put('/me', {
                old_password: oldPasswordField.current.value,
                password: passwordField.current.value,
            })
            if (response.ok) {
                setFormErrors({})
                flash('Your password has been updated!', 'success')
                navigate('/')
            }
        }

    }

    useEffect(() => {
        oldPasswordField.current.focus()
    },[]
    )

    return (
        <Body sidebar>
            <h1>Change Password</h1>
            <Form onSubmit={onSubmit}>
                <InputField name='oldPassword' type ='password' label='Old Password' error={formErrors.oldPassword} fieldRef={oldPasswordField} />
                <InputField name='password' type='password' label='New Password' error={formErrors.password} fieldRef={passwordField} />
                <InputField name='password2' type='password' label='New Password again' error={formErrors.password2} fieldRef={password2Field} />
                <Button variant='primary' type='submit'>Change Password Now!</Button>
            </Form>
        </Body>
    )
}