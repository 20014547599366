import { useState, useEffect } from 'react'
import Spinner from 'react-bootstrap/Spinner'
import { useApi } from '../contexts/ApiProvider'
import Post from './Post'
import More from './More'
import Write from './Write'

// Posts has two destructed props: content and write, since props is an object
export default function Posts({ content, write }) {
    // it has two states: posts and pagination which are NOT immutable and require re-rendering
    const [posts, setPosts] = useState();
    const [pagination, setPagination] = useState();
    // it has a custom hook to use the api context
    const api = useApi();

    let url;
    switch (content) {
        case 'feed':
        case undefined:
            url = '/feed'
            break
        case 'explore':
            url = '/posts'
            break
        default:
            url = `/users/${content}/posts`
            break
    }
    useEffect(() => {
        (async () => {
            const response = await api.get(url);
            if (response.ok) {
                setPosts(response.body.data);
                setPagination(response.body.pagination);
            }
            else {
                setPosts(null);
            }
        })();
    }, [api, url]);

    const loadNextPage = async () => {
        const response = await api.get(url, {
            after: posts[posts.length - 1].timestamp,
        })
        if (response.ok) {
            setPosts([...posts, ...response.body.data])
            setPagination(response.body.pagination);
        }
    }

    const showPosts = (newPost) => {
        setPosts([newPost, ...posts])
    }

    return (
        <>
            {write && <Write showPosts={showPosts} />}
            {/* if posts is undefined, show a spinner */}
            {posts === undefined ?
                <Spinner animation='border' />
                :
                <>
                    {/* elif posts is an empty array, show a message */}
                    {posts.length === 0 ?
                        <p> There are no blog posts.</p>
                        :
                        <>
                            {/* elif posts is null, show an error message */}
                            {posts === null ?
                                <p> Could not retrieve posts.</p>
                                :
                                <>
                                    {/* else show the posts by mapping through the posts array */}
                                    {/* {posts.map(post => <Post post={post} />)} */}
                                    {posts.map((post, index) => <Post key={index} post={post} />)}
                                    <More pagi={pagination} loadNext={loadNextPage} />
                                </>
                            }
                        </>
                    }
                </>
            }
        </>
    );
}