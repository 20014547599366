import { useState, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Body from '../components/Body';
import { useFlash } from '../contexts/FlashProvider';

const REACT_APP_UPLOAD_FULL_API_URL = process.env.REACT_APP_UPLOAD_FULL_API_URL ||
  'http://linode1.cocajola.xyz:5003/docu';

export default function UploaderPage() {
    const [uploadResponse, setUploadResponse] = useState('');
    const [isUploading, setIsUploading] = useState(false);
    const fileInputRef = useRef(null);
    const flash = useFlash();

    const handleUpload = () => {
        const fileInput = fileInputRef.current;
        if (fileInput.files.length === 0) {
            alert('Please select a file to upload.');
            return;
        }

        setIsUploading(true);
        const formData = new FormData();
        formData.append('docu', fileInput.files[0]);

        fetch(REACT_APP_UPLOAD_FULL_API_URL, {
            method: 'POST',
            body: formData
        })
            .then(response => {
                setIsUploading(false);
                if (!response.ok) {
                    flash('Failed to upload. Are you choosing txt/jpg/pdf?', 'danger');
                    console.log('upload false');
                    fileInput.value = ''; // Clear the file input
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                flash('Upload succeeded!', 'success');
                fileInput.value = ''; // Clear the file input
                setUploadResponse(JSON.stringify(data, null, 2));
            })
            .catch(error => {
                setIsUploading(false);
                flash('Failed to upload. Are you choosing txt/jpg/pdf?', 'danger');
                console.log('upload false');
            });
    };

    const triggerFileInputClick = () => {
        fileInputRef.current.click();
    };

    return (
        <Body sidebar>
            <div>
                <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: 'none' }} // Hide the actual file input
                />
                {/* Custom button that triggers the hidden file input */}
                <Button variant="secondary" onClick={triggerFileInputClick}>
                    Browse...
                </Button>
                <br />
                <br />
                <Button variant="primary" onClick={handleUpload} disabled={isUploading}>
                    {isUploading 
                    ? <Spinner as="span" animation="border" size="sm" /> 
                    : 'Upload!'}
                </Button>
                <br />
                <br />
                {uploadResponse && <pre>{uploadResponse}</pre>}
            </div>
        </Body>
    );
}