import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function Analytics ({ children }) {
  const location = useLocation();

  // send pageview to Google Analytics when location changes
  useEffect(() => {
    ReactGA.send('pageview');
    console.log('ga pv sent');
  }, [location.pathname]);

  return children;
};