import { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Body from '../components/Body'

export default function ClickerPage() {
    const [count, setCount] = useState(0)

    return (
        <Body sidebar>
        <div>
            <Button variant='primary' onClick={() => setCount(count + 1)}>
                Clicker Button Here
            </Button>
            <br/>
            <br/>
            <p>
                You have clicked {count} times!
            </p>
        </div>
        </Body>
    )
}