import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useUser } from '../contexts/UserProvider';
import Spinner from 'react-bootstrap/Spinner';
import Image from 'react-bootstrap/Image';
import { NavLink } from 'react-router-dom';

export default function Header() {
  const { user, logout } = useUser();

  return (
    <Navbar bg="light" sticky="top" className="Header">
      <Container fluid>
        <Navbar.Brand>
            <br />
        </Navbar.Brand>
        <Navbar.Collapse className="justify-content-end">
          <Nav>
            {user === undefined ? (
              <Spinner animation="border" />
            ) : (
              <>
                {user !== null && (
                  <NavDropdown
                    title={<Image src={user.avatar_url + '&s=32'} roundedCircle />}
                    align="end"
                  >
                    <NavDropdown.Item as={NavLink} to={'/user/' + user.username}>
                      Profile
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item as={NavLink} to={'/password'}>
                      Change Password
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item onClick={logout}>
                      Logout
                    </NavDropdown.Item>
                  </NavDropdown>
                )}
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}