import React, { useState, useEffect } from 'react';
import Body from '../components/Body';
//import Button from 'react-bootstrap/Button'
//import { useFlash } from '../contexts/FlashProvider'
import * as PusherPushNotifications from "@pusher/push-notifications-web";
import Pusher from 'pusher-js';

const PUSHER_INSTANCE_ID = process.env.REACT_APP_PUSHER_INSTANCE_ID
const PUSHER_APP_KEY = process.env.REACT_APP_PUSHER_APP_KEY
const PUSHER_APP_CLUSTER = process.env.REACT_APP_PUSHER_APP_CLUSTER

export default function PusherPage() {
  const [data, setData] = useState(null); // Define `data` state

  useEffect(() => {
    const pusher = new Pusher(PUSHER_APP_KEY, {
      cluster: PUSHER_APP_CLUSTER
    });
  
    const channel = pusher.subscribe('AAPL');
    channel.bind('new-price', function(newData) {
      setData(newData); // Update the `data` state with the new data
    });
  
    try {
      const beamsClient = new PusherPushNotifications.Client({
        instanceId: PUSHER_INSTANCE_ID,
      });
  
      beamsClient.start()
        .then(() => beamsClient.addDeviceInterest('AAPL'))
        .catch(e => console.error('Could not add device interest', e));
    } catch (e) {
      console.error('Could not start Pusher Beams client', e);
      // Here you can set an error state or perform other actions based on the error
    }
  
    return () => {
      channel.unbind('new-price');
      pusher.unsubscribe('AAPL');
    };
  }, []); // Empty dependency array ensures this runs once on mount and not on updates

  return (
    <Body sidebar>
        <div style={{display:'flex', justifyContent:'space-around'}}>
          {/* Correctly render `data` */}
          <p>{data ? JSON.stringify(data) : 'No data'}</p>
        </div>
    </Body>
  );
}
