import Container from 'react-bootstrap/Container'
import ApiProvider from './contexts/ApiProvider'
import FlashProvider from './contexts/FlashProvider'
import UserProvider from './contexts/UserProvider'
import Header from './components/Header'
import FeedPage from './pages/FeedPage'
import ExplorePage from './pages/ExplorePage'
import LoginPage from './pages/LoginPage'
import UserPage from './pages/UserPage'
import PolicyPage from './pages/PolicyPage'
import ClickerPage from './pages/ClickerPage'
import RegistrationPage from './pages/RegistrationPage'
import SmsPage from './pages/SmsPage'
import ReflectPage from './pages/ReflectPage'
import UploaderPage from './pages/UploaderPage'
import PusherPage from './pages/PusherPage'
import UrlsPage from './pages/UrlsPage'
import PublicRoute from './components/PublicRoute'
import PrivateRoute from './components/PrivateRoute'
import EditUserPage from './pages/EditUserPage'
import ChangePasswordPage from './pages/ChangePasswordPage'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import ResetRequestPage from './pages/ResetRequestPage'
import ResetPage from './pages/ResetPage'
import ReactGA from 'react-ga4'
import { useEffect } from 'react';
import Analytics from './components/Analytics'

const App = () => {
  // initialize GA in useEffect
  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_NO)
  }, [])

  // return the App component code
  return (
    <Container fluid className='App'>
      <BrowserRouter>
        <FlashProvider>
          <ApiProvider>
            <UserProvider>
              <Analytics>
                <Header />
                {/* Routes component works as C++ switch with break and catch-all */}
                <Routes>
                  <Route path='/login' element={<PublicRoute><LoginPage /></PublicRoute>} />
                  <Route path='/register' element={<PublicRoute><RegistrationPage /></PublicRoute>} />
                  <Route path='/reset-request' element={<PublicRoute><ResetRequestPage /></PublicRoute>} />
                  <Route path='/reset' element={<PublicRoute><ResetPage /></PublicRoute>} />
                  <Route path='*' element={
                    <PrivateRoute>
                      <Routes>
                        <Route path='/' element={<FeedPage />} />
                        <Route path='/explore' element={<ExplorePage />} />
                        {/* dynamic route with username parameter as placeholder */}
                        <Route path='/user/:username' element={<UserPage />} />
                        <Route path='/edit' element={<EditUserPage />} />
                        <Route path='/password' element={<ChangePasswordPage />} />
                        <Route path='/policy' element={<PolicyPage />} />
                        <Route path='/clicker' element={<ClickerPage />} />
                        <Route path='/reflect' element={<ReflectPage />} />
                        <Route path='/uploader' element={<UploaderPage />} />
                        <Route path='/pusher' element={<PusherPage />} />
                        <Route path='/urls' element={<UrlsPage />} />
                        <Route path='/sms' element={<SmsPage />} />
                        <Route path='*' element={<Navigate to='/' />} />
                      </Routes>
                    </PrivateRoute>
                  } />
                </Routes>
              </Analytics>
            </UserProvider>
          </ApiProvider>
        </FlashProvider>
      </BrowserRouter>
    </Container>
  )
}

export default App